/* Add a black background color to the top navigation */
nav {
    background-color: #333;
    overflow: hidden;
  }
  
   /* Style the links inside the navigation bar */
   nav a.logo {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 5px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  /* Style the links inside the navigation bar */
  nav a {
    float: right;
    color: #f2f2f2;
    text-align: center;
    padding: 5px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  /* Change the color of links on hover */
  nav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  /* Add a color to the active/current link */
  nav a.active {
    background-color: #4CAF50;
    color: white;
  }